<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Toegangscontrole</h1>
        
        <b-alert variant="info" show>
            Om externe personen te laten scannen zonder een account voor hen aan te maken, kun je in de scan-app inloggen met een 6-cijferige code. Deze codes worden automatisch verwijderd zodra het evenement is afgelopen.
        </b-alert>
        
        <b-card no-body class="mb-3">
            <b-card-header>
                <h3 class="fs-5 mb-0">
                    Scancodes
                </h3>
            </b-card-header>
            <b-card-body>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Rechten</th>
                            <th>Actie</th>
                        </tr>
                    </thead>
                </table>
                <b-button variant="primary">Scancode aanmaken</b-button>
            </b-card-body>
        </b-card>

        <b-row>
            <b-col md="6">
                <b-card no-body class="mb-3">
                    <b-card-header>
                        <h3 class="fs-5 mb-0">
                            Kleine evenementen
                        </h3>
                    </b-card-header>
                    <b-card-body>
                        <p>Als je niet meer dan 2000 tickets verkoopt, kun je het beste de mobiele app gebruiken. Hiermee kun je snel tickets scannen en met meerdere scanners tegelijk werken.</p>
                        <h4>Scannen vanaf de computer</h4>
                        <p>
                            Heb je een computer met een USB scanner? Dan kun je gebruik maken van de online scanner.
                            <br/>
                            <br/>
                            <b-button :to="`/manage/events/` + event.id + `/scanner`" variant="primary">Open online scanner</b-button>
                        </p>
                        <h4>Scannen vanaf je telefoon</h4>
                        <p>De app is beschikbaar in de App Store en de Play Store. Zoek op "tixgo.nl" of klik op de onderstaande knop.</p>
                        <p>
                            Na het downloaden van de app kun je met je e-mailadres en wachtwoord inloggen, je evenement selecteren en je kunt beginnen.
                            <br/>
                            <br/>
                            <b-button variant="primary" class="mr-2 mb-2">Android</b-button>
                            <b-button variant="primary" class="mr-2 mb-2">iPhone (iOS)</b-button>
                        </p>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card no-body>
                    <b-card-header>
                        <h3 class="fs-5 mb-0">
                            Grote evenementen
                        </h3>
                    </b-card-header>
                    <b-card-body>
                        <p>Als je meer dan 2000 tickets verkoopt, raden wij aan om contact met ons op te nemen voor speciale scan apparatuur. Wij beschikken over meerdere professionele scanners en kunnen je adviseren over het benodigde aantal scanners. Over het algemeen raden wij aan om één scanner per 800 bezoekers te gebruiken. Dus als je bijvoorbeeld 4000 bezoekers verwacht, adviseren wij 5 scanners.</p>
                        <p>Je kunt contact met ons opnemen door een e-mail te sturen naar contact@tixgo.nl. Vermeld hierbij de naam van het evenement en wij nemen zo snel mogelijk contact met je op om de mogelijkheden te bespreken.</p>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        
      </b-container>
    </div>
</template>
  
<script>
    export default {
        metaInfo: {
            title: 'Toegangscontrole',
        },
        components: {},
        data() {
            return {
                event: {}
            }
        },
        computed: {},
        methods: {
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        if (response.data) {
                            this.event = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
        },
        created() {
            this.fetchEvent();
        },
    }
</script>